/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
  Modal, Button, Segment, Header, List,
} from 'semantic-ui-react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Dashboard } from '@uppy/react';
import { API_URL } from '../../../../configs';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

@inject('store')
@observer
class EmailSuppressionModal extends Component {
  state = {
    modalOpen: false,
  }

  @observable loading = false;

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  }

  @action
  getUnsubscribesList = async () => {
    try {
      this.loading = true;
      const csvString = '"address","created_at"\n';
      const { data } = await axios.get(`${API_URL}/api/em/do-not-mail?emailDomain=${this.props.store.emailManagement.activeEmailDomainName}`);
      if (data.success) {
        // console.log(JSON.stringify(data.data.mailgunRes.items));
        const unsubscribesList = data.data.mailgunRes.items.reduce((acc, curr) => (`${acc}"${curr.address}","${curr.created_at}"\n`), csvString);
        this.link.href = `data:text/csv,${encodeURI(unsubscribesList)}`;
        this.link.download = 'unsubscribes.csv';
        this.link.click();
      } else {
        this.props.store.failure('Something went wrong...');
      }
    } catch (e) {
      this.props.store.failure('Something went wrong...');
    } finally {
      this.loading = false;
    }
  }

  render() {
    return (
      <Fragment>
        <Header as="h3" attached="top" style={{ marginTop: '1rem' }} className="noEmphasisHeader">
          Upload Unsubscribes CSV

          <a
            ref={(link) => { this.link = link; }}
            style={{ display: 'none' }}
          />
        </Header>
        <Segment attached>
          You may upload your list of addresses that we should not email to.
          <List bulleted style={{ marginTop: 3 }}>
            <List.Item>
              Each email address should be separated by a newline character.
            </List.Item>
            <List.Item>
              Duplicate addresses will be overwritten.
            </List.Item>
          </List>
        </Segment>

        <Button
          attached="bottom"
          onClick={this.openModal}
          content="Upload Unsubscribes CSV"
        />

        <Header as="h3" attached="top" className="noEmphasisHeader">
          Download Unsubscribes CSV
        </Header>
        <Button
          attached
          disabled={this.loading}
          loading={this.loading}
          onClick={this.getUnsubscribesList}
          content="Download Unsubscribes CSV"
        />

        <Modal
          size="large"
          open={this.state.modalOpen}
          onClose={this.closeModal}
        >
          <Modal.Header>Upload Do-Not-Mail CSV</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Please upload a list of emails separated by newline.
            </Modal.Description>

            <br />

            <Dashboard
              uppy={this.props.store.emailSuppression.uppy}
              width="100%"
              showProgressDetails
              hideUploadButton
              hideRetryButton
              locale={{
                strings: {
                  dropHereOr: 'Drop files/templates here or %{browse}',
                  browse: 'browse',
                },
              }}
              note={`Limitations
            | Max Count: 1 CSV.
            | Max Filesize: < 10mb
            | Valid Formats: .csv
            `}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="Download Sample CSV"
              as="a"
              href={`data:text/csv,${encodeURI('john@aol.com\nchris@gmail.com\ntest@test.com')}`}
              download="sample.csv"
            />
            <Button
              positive
              icon="cloud upload"
              content="Upload"
              labelPosition="left"
              onClick={async () => {
                await this.props.store.emailSuppression.uploadCSV();
                this.closeModal();
              }}
              loading={this.props.store.emailSuppression.loading}
              disabled={this.props.store.emailSuppression.loading}
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default EmailSuppressionModal;
