export const userRoleType = {
  superadmin: 'superadmin',
  admin: 'admin',
  user: 'user',
};

export const clientStatusType = {
  archived: 'archived',
  disabled: 'disabled',
  enabled: 'enabled',
};

export const planNames = {
  free: 'Free',
  startup: 'Startup',
  growth: 'Growth',
  scale: 'Scale',
  enterpriseLite: 'Enterprise Lite',
  enterprisePlus: 'Enterprise Plus',
};

export const planLimits = {
  Free: 100,
  Startup: 750,
  Growth: 1500,
  Scale: 3000,
  'Enterprise Lite': 6000,
  'Enterprise Plus': Infinity,
};

export const colors = {
  purple: 'rgb(121, 19, 198)',
  yellow: 'rgb(249, 200, 14)',
  orange: 'rgb(248, 102, 36)',
  red: 'rgb(234, 53, 70)',
  teal: 'rgb(67, 188, 205)',
  disabled: 'grey',
  primary: '#7ac142',
  secondary: '#222328',
};

export const modalTypes = {
  add: 'add',
  remove: 'remove',
  edit: 'edit',
};

export const TABLE_ITEMS_PER_PAGE = 20;

export const noPremiumMessage = 'Please upgrade to premium.';

export const semanticColors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];

export const toastTypes = {
  // Email Management
  saveEmailSettings: 'saveEmailSettings',
  saveEmailLogic: 'saveEmailLogic',
  setActiveEmailDomainName: 'setActiveEmailDomainName',
  getEmailDomains: 'getEmailDomains',
  getTemplate: 'getTemplate',
  getTemplates: 'getTemplates',
  addTemplate: 'addTemplate',
  uploadTemplates: 'uploadTemplates',
  updateTemplate: 'updateTemplate',
  deleteTemplate: 'deleteTemplate',
  sendTestEmail: 'sendTestEmail',
  getNSRecords: 'getNSRecords',
  addEmailDomain: 'addEmailDomain',
  verifyRecords: 'verifyRecords',
  // Account Status
  getAccountDetails: 'getAccountDetails',
  cancelSubscription: 'cancelSubscription',
  // Admin
  spoofClient: 'spoofClient',
  setClientStatus: 'setClientStatus',
  downloadCaptures: 'downloadCaptures',
  // Analytics
  getCaptureList: 'getCaptureList',
  analyticsDownloadCaptures: 'analyticsDownloadCaptures',
  // Auth
  idTokenCallback: 'idTokenCallback',
  shopifyLogin: 'shopifyLogin',
  wixLogin: 'wixLogin',
  salesforceMarketincCloudLogin: 'salesforceMarketincCloudLogin',
  sendCustomVerificationEmail: 'sendCustomVerificationEmail',
  sendCustomPasswordResetEmail: 'sendCustomPasswordResetEmail',
  verifyCode: 'verifyCode',
  // ClientHooks
  getAllHooks: 'getAllHooks',
  deleteHook: 'deleteHook',
  addHook: 'addHook',
  updateHook: 'updateHook',
  // EmailSuppression
  uploadCSV: 'uploadCSV',
  addEmail: 'addEmail',
  deleteEmail: 'deleteEmail',
  // Forms
  addFormLink: 'addFormLink',
  updateForm: 'updateForm',
  // Payments
  getAvailablePlans: 'getAvailablePlans',
  verifyPromoCode: 'verifyPromoCode',
  updateStripePayment: 'updateStripePayment',
  confirmPaymentAndSubscribe: 'confirmPaymentAndSubscribe',
  // GoogleAdwords
  getAudiences: 'getAudiences',
  updateConfigs: 'updateConfigs',
  setup: 'setup',
  // GoogleTagManager
  googleTagManagerUpdateConfigs: 'googleTagManagerUpdateConfigs',
  googleTagManagerSetUp: 'googleTagManagerSetUp',
  createWorkspace: 'createWorkspace',
  addCaptureTag: 'addCaptureTag',
  publishWorkspace: 'publishWorkspace',
  // HubSpot
  hubSpotUpdateConfigs: 'hubSpotUpdateConfigs',
  getTokens: 'getTokens',
  // MailChimp
  mailChimpUpdateConfigs: 'mailChimpUpdateConfigs',
  mailChimpGetTokens: 'mailChimpGetTokens',
  // OMI366
  omiUpdateConfigs: 'omiUpdateConfigs',
  // SalesForce
  salesForceUpdateConfigs: 'salesForceUpdateConfigs',
  salesForceGetTokens: 'salesForceGetTokens',
  // SalesForceMarketingCloud
  salesForceMarketingCloudUpdateConfigs: 'salesForceMarketingCloudUpdateConfigs',
  salesForceMarketingCloudVerifyCode: 'salesForceMarketingCloudVerifyCode',
  // Shopify
  shopifyUpdateConfigs: 'shopifyUpdateConfigs',
  shopifySetUp: 'shopifySetUp',
  shopifyGetTokens: 'shopifyGetTokens',
  paymentUrl: 'paymentUrl',
  activateCharge: 'activateCharge',
  // Typeform
  typeFormUpdateConfigs: 'typeFormUpdateConfigs',
  typeFormGetTokens: 'typeFormGetTokens',
  approveForm: 'approveForm',
  // WIX
  linkAccounts: 'linkAccounts',
  wixUpdateConfigs: 'wixUpdateConfigs',
  getEmbeddedScriptParams: 'getEmbeddedScriptParams',
  // Blitz
  blitzUpdateConfigs: 'blitzUpdateConfigs',
  // Pipedrive
  pipedriveUpdateConfigs: 'pipedriveUpdateConfigs',
  // Fanbridge
  fanbridgeUpdateConfigs: 'fanbridgeUpdateConfigs',
};
