import qs from 'query-string';
import firebase from 'firebase/compat/app';
// import 'firebase/database';
import {
  ref, off,
} from 'firebase/database';
import 'firebase/compat/auth';
import { action, observable } from 'mobx';
import { toast } from 'react-toastify';
import { create } from 'mobx-persist';
import { FIREBASE_CONFIGS } from '../configs';
import Admin from './Admin';
import AccountStatus from './AccountStatus';
import Auth from './Auth';
import Analytics from './Analytics';
import Realtime from './Realtime';
import ClientHooks from './ClientHooks';
import EmailManagement from './EmailManagement';
import EmailSuppression from './EmailSuppression';
import Forms from './Forms';
import Integrations from './Integrations/index';
import Notifications from './Notifications';
import Payments from './Payments';
import Tour from './Tour';
import UI from './UI';
import AMP from './AMP';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class Store {
  constructor() {
    this.firebase = firebase.initializeApp(FIREBASE_CONFIGS);
    this.initQueryParams = qs.parse(window.location.search);

    this.auth = new Auth(this);
    this.admin = new Admin(this);
    this.accountStatus = new AccountStatus(this);
    this.analytics = new Analytics(this);
    this.realtime = new Realtime(this);
    this.clientHooks = new ClientHooks(this);
    this.emailManagement = new EmailManagement(this);
    this.emailSuppression = new EmailSuppression(this);
    this.forms = new Forms(this);
    this.integrations = new Integrations(this);
    this.notifications = new Notifications(this);
    this.payments = new Payments(this);
    this.tour = new Tour(this);
    this.ui = new UI(this);
    this.amp = new AMP(this);
    this.autoClose = false;
    const { partner, instance } = this.initQueryParams;
    /** Wix auto-login */
    if (partner === 'wix' && instance) {
      this.auth.wixLogin(instance);
    }
    Promise.all([
      hydrate('forms', this.forms),
    ]);
  }

  @observable
  initQueryParams = {};

  @observable
  appInitFlag = false;

  @action
  initAppState = async () => {
    if (!this.appInitFlag) {
      this.appInitFlag = true;
      const { user } = this.auth;
      this.notifications.attachNotificationsListener();

      if (user.clientId !== 'superadmin' && user.clientId) {
        if (this.auth.paidPlan) {
          this.analytics.getInfluencedSaves();
        }

        this.analytics.getRawAnalytics();
        this.payments.getAvailablePlans();
        this.accountStatus.getAccountDetails();

        this.forms.getFormLinks();
        this.clientHooks.getAllHooks();

        this.emailManagement.getEmailDomains().then(this.emailManagement.getTemplates);
      }
    }
  }

  @action
  resetAppState = () => {
    if (this.appInitFlag) {
      // turns off the notifications listener
      // this.firebase.database().ref(`/clientNotifications/${this.auth.user.clientId}`).off();
      off(ref(this.firebase.database(), `/clientNotifications/${this.auth.user.clientId}`));

      // clears out all stores variables
      this.accountStatus.reset();
      this.admin.reset();
      this.analytics.reset();
      this.auth.reset();
      this.clientHooks.reset();
      this.forms.reset();
      this.integrations.reset();
      this.notifications.reset();
      this.payments.reset();
      this.tour.reset();
      this.emailManagement.reset();
      this.emailSuppression.reset();
      this.amp.reset();
      this.appInitFlag = false;
    }
  }
  clearToast = (id) => {
    toast.dismiss(id);
  }

  // clear all toasts when component unmounts
  clearAllToasts = () => {
    toast.dismiss();
  }

  success = (title = '', id) => {
    toast.success(title, {
      toastId: `${id} success`,
    });
  }

  failure = (title = '', id) => {
    toast.error(title, {
      autoClose: this.autoClose,
      toastId: id,
    });
  }
}

export default Store;
