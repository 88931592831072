/* eslint-disable max-len */
import React, { Component } from 'react';
import {
  Table, Button, Loader, Icon, Label, Pagination, Progress,
} from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { keygen } from '../../../../helper';
import { TABLE_ITEMS_PER_PAGE } from '../../../../enums';
import './Overview.css';

@inject('store')
@observer
class Overview extends Component {
  state = {
    activePage: 1,
  }

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage })

  rowsInProgress = () => {
    const rowsArray = this.props.store.forms.urlsInProgress;

    return rowsArray.map(url => (
      <Table.Row key={keygen()} warning>
        <Table.Cell>
          <Icon name="attention" />
          <b>
            Adding your form at
            {' '}
            <u>{`${url}`}</u>
          </b>
        </Table.Cell>

        <Table.Cell colSpan={2}>
          <Progress percent={100} indicating size="tiny" content="Fetching" />
        </Table.Cell>
      </Table.Row>
    ));
  }

  snippetStatus = (url) => {
    if (this.props.store.forms.loading && this.props.store.forms.currentUrl === url) {
      return <Loader active inline />;
    } else if (this.props.store.forms.formLinks[url].snippetStatus === false) {
      return (
        <Button
          onClick={() => this.props.store.forms.verifySnippetInstall(url)}
          inverted
          icon="warning"
          color="orange"
        />
      );
    } else if (this.props.store.forms.formLinks[url].snippetStatus) {
      return (
        <Icon
          onClick={() => this.props.store.forms.verifySnippetInstall(url)}
          name="check"
          color="green"
        />
      );
    } else {
      return false;
    }
  }

  rows = () => {
    const { remove } = this.props;
    const rowsArray = Object.keys(this.props.store.forms.formLinks);

    return rowsArray.slice(
      0 + (this.state.activePage - 1)
      * TABLE_ITEMS_PER_PAGE, 0
      + (this.state.activePage * TABLE_ITEMS_PER_PAGE),
    )
      .map(url => (
        <Table.Row key={keygen()}>
          <Table.Cell>

            <Icon name="caret right" />
            <a
              href={`https://${url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`https://${url}`}
            </a>
          </Table.Cell>

          <Table.Cell textAlign="center" collapsing>
            {this.snippetStatus(url)}
          </Table.Cell>

          <Table.Cell collapsing>
            <Button.Group>
              {
                this.props.store.auth.paidPlan
                  ? (
                    <Button
                      basic
                      icon="pencil"
                      url={url}
                      onClick={this.props.edit}
                    />
                  )
                  : null
              }
              <Button
                onClick={remove}
                basic
                icon="trash"
                url={url}
              />
            </Button.Group>
          </Table.Cell>
        </Table.Row>
      ));
  }

  noFormsMessage = () => (
    <Table.Row>
      <Table.Cell>
        <Label ribbon color="green" size="large">
          Looks like you have no forms yet. Press the green plus button to start tracking a form.
        </Label>
      </Table.Cell>
    </Table.Row>
  )

  render() {
    const { add } = this.props;
    const rowsInProgressLength = this.props.store.forms.urlsInProgress.length;
    const rows = (this.rows().length + rowsInProgressLength) === 0
      ? this.noFormsMessage() : this.rows();
    const rowsInProgress = rowsInProgressLength === 0 ? null : this.rowsInProgress();

    return (
      <div className="sideScrollingTable">
        <Table striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Form</Table.HeaderCell>
              <Table.HeaderCell>Snippet Status</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rowsInProgress}
            {rows}
          </Table.Body>
          <Table.Footer>
            <Table.Row columns="equal">
              <Table.HeaderCell colSpan="3">
                <Button
                  onClick={add}
                  icon
                  labelPosition="left"
                  positive
                >
                  <Icon name="plus" />
                  Add a Form
                </Button>
                {
                  Math.ceil(Object.keys(this.props.store.forms.formLinks).length / 20) > 1 ? (
                    <Pagination
                      activePage={this.state.activePage}
                      onPageChange={this.handlePaginationChange}
                      totalPages={Math.ceil(Object.keys(this.props.store.forms.formLinks).length / 20)}
                      style={{ position: 'relative', left: '50%', transform: 'translateX(calc(-50% - 36.5px))' }}
                    />
                  ) : null
                }
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}

export default Overview;
