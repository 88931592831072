import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';

import Webhooks from './Webhooks';
import Emails from './Emails';
import StopList from './StopList';

class Reporting extends Component {
  panes = [
    {
      menuItem: 'Leads Data',
      render: () => <Emails />,
    },
    {
      menuItem: 'Webhooks',
      render: () => <Webhooks />,
    },
    {
      menuItem: 'E-mail stop list',
      render: () => <StopList />,
    },
  ];

  render() {
    return (
      <Tab
        panes={this.panes}
        menu={{ secondary: true, pointing: true }}
      />
    );
  }
}

export default Reporting;
