import _ from 'lodash';
import axios from 'axios';
import { observable, action } from 'mobx';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

import { API_URL } from '../configs';
import { toastTypes } from '../enums';

class EmailSuppression {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.uppyInit();
  }

  @observable unsubscribesList = [];
  @observable loading = false;

  uppyInit() {
    this.uppy = Uppy({
      meta: { type: 'templates' },
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: 10 * 1024 * 1024,
        allowedFileTypes: ['.csv'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      debug: process.env.REACT_APP_NODE_ENV !== 'production',
    });

    this.uppy.use(XHRUpload, {
      id: 'XHRUpload',
      endpoint: `${API_URL}/api/em/do-not-mail`,
      method: 'POST',
      formData: true,
      fieldName: 'file',
      headers: {
        Authorization: null,
      },
    });

    this.uppy.on('complete', () => {
      this.loading = false;
    });
  }

  @action
  reset() {
    this.loading = false;
    this.unsubscribesList = [];
    this.uppy.close();
    this.uppyInit();
  }

  @action
  uploadCSV = async () => {
    this.loading = true;
    const xhrUpload = this.uppy.getPlugin('XHRUpload');
    _.set(xhrUpload, 'opts.headers.Authorization', await this.rootStore.firebase.auth().currentUser.getIdToken());
    _.set(xhrUpload, 'opts.headers.EmailDomain', this.rootStore.emailManagement.activeEmailDomainName);

    try {
      const res = await this.uppy.upload();

      if (_.get(res, 'successful[0].response.body.success')) {
        const totalEmailsSuppressionCount = _.get(res, 'successful[0].response.body.data.totalEmailsSuppressionCount');
        this.rootStore.success(`Added ${totalEmailsSuppressionCount} emails to unsubscribes list.`, toastTypes.uploadCSV);
        this.rootStore.clearToast(toastTypes.uploadCSV);
      } else {
        this.rootStore.failure('Something went wrong, please check your files again.', toastTypes.uploadCSV);
      }
    } catch (err) {
      this.rootStore.failure('Error uploading CSV.', toastTypes.uploadCSV);
    } finally {
      this.uppy.reset();
      this.loading = false;
    }
  }

  @action addEmail = async (hook) => {
    try {
      const res = await axios.post(`${API_URL}/api/em/do-not-one-mail/`, {
        emailDomain: hook.emailDomain,
        email: hook.email,
      }, {
        headers: {
          Authorization: await this.rootStore.firebase.auth().currentUser.getIdToken(),
          EmailDomain: this.rootStore.emailManagement.activeEmailDomainName,
        },
      });

      if (res.data.success) {
        this.rootStore.success('Email added successfully!', toastTypes.addEmail);
        this.rootStore.clearToast(toastTypes.addEmail);
      } else {
        this.rootStore.failure('Failed to add a email.', toastTypes.addEmail);
      }
    } catch (e) {
      this.rootStore.failure('Failed to add a email.', toastTypes.addEmail);
    } finally {
      // this.getAllHooks();
    }
  }

  @action deleteEmail = async (hookId) => {
    try {
      const res = await axios.delete(`${API_URL}/api/clientHooks/${hookId}`);

      if (res.data.success) {
        this.rootStore.success('Email deleted successfully!', toastTypes.deleteEmail);
        this.rootStore.clearToast(toastTypes.deleteEmail);
      } else {
        this.rootStore.failure('Failed to delete email.', toastTypes.deleteEmail);
      }
    } catch (e) {
      this.rootStore.failure('Failed to delete email.', toastTypes.deleteEmail);
    } finally {
      this.getAllHooks();
    }
  }
}

export default EmailSuppression;
